.homepage_carousel {
  height: 70vh;
}

#landing_content {
  position: absolute;
  top: 75%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
}

/* Set the new background */
#landing_background {
  position: absolute;
  top: 18vh;
  left: 5px;
  width: 98.7%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
    url("../assets/images/otiva_landing.webp");
  background-size: cover;
  background-position: center;
  /* filter: blur(8px); Optional blur effect */
  z-index: -1; /* Send it behind the content */
}

.landing_description {
  font-size: 1.3rem;
  font-weight: 250;
  padding-left: 3vw;
  text-align: left;
  width: 40vw;
}

#landing_tagline {
  font-size: 64px;
  font-weight: 365;
  font-family: "Playfair Display", serif;
}

#landing_rule {
  margin: 3vh 0px 5vh 0px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  width: 25vw;
}

#landing_about {
  display: flex;
  padding: 13vh 5vw 13vh 1vw;
  color: rgb(74, 73, 73);
}

#landing_industry {
  display: flex;
  flex-direction: column;
  padding: 1vw;
  padding-top: 5vh;
  background-color: white;
}

.industry_item {
  display: flex;
  text-align: left;
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 250;
  padding: 1vw;
  padding-left: 0;
  width: 15vw;
}

@media screen and (max-width: 450px) {
  .homepage_carousel {
    height: 50vh;
  }
  #landing_background {
    position: absolute;
    top: 3vh;
    left: 2px;
    width: 97.5%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
      url("../assets/images/otiva_landing.webp");
    background-size: cover;
    background-position: center;
    /* filter: blur(8px); Optional blur effect */
    z-index: -1; /* Send it behind the content */
  }
  .landing_description {
    font-weight: 250;
    padding-left: 3vw;
    text-align: left;
    width: 100%;
    margin: 3vh auto;
  }
  #landing_content {
    position: absolute;
    top: 45%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
  }
  #landing_tagline {
    font-size: 32px;
    font-weight: 365;
    font-family: "Playfair Display", serif;
  }

  #landing_about {
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    color: rgb(74, 73, 73);
    background-color: rgb(237, 238, 238);
  }

  .landing_desc {
    padding: 2vh 0;
  }
  #landing_industry {
    display: flex;
    flex-direction: column;
    padding: 3vw;
    padding-top: 5vh;
    background-color: white;
  }

  .industry_item {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1rem;
    font-weight: 250;
    padding: 1vw;
    padding-left: 0;
  }
}

@media screen and (max-width: 1450px) and (min-width: 901px) {
  #landing_content {
    position: absolute;
    font-size: 1.1rem;
    top: 75%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
  }

  #landing_background {
    position: absolute;
    top: 18vh;
    left: 5px;
    width: 97.5%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
      url("../assets/images/otiva_landing.webp");
    background-size: cover;
    background-position: center;
    /* filter: blur(8px); Optional blur effect */
    z-index: -1; /* Send it behind the content */
  }

  #landing_tagline {
    font-size: 28px;
    font-weight: 365;
    font-family: "Playfair Display", serif;
  }
}

@media screen and (max-width: 900px) and (min-width: 451px) {
  .homepage_carousel {
    height: 60vh;
  }
  #landing_background {
    position: absolute;
    top: 6vh;
    left: 2px;
    width: 97.5%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
      url("../assets/images/otiva_landing.webp");
    background-size: cover;
    background-position: center;
    /* filter: blur(8px); Optional blur effect */
    z-index: -1; /* Send it behind the content */
  }

  .landing_description {
    font-weight: 250;
    padding-left: 5vw;
    text-align: left;
    width: 80%;
    margin: 3vh auto;
  }

  #landing_content {
    position: absolute;
    top: 58%;
    left: 36%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
  }
  #landing_tagline {
    font-size: 32px;
    font-weight: 365;
    font-family: "Playfair Display", serif;
  }

  #landing_about {
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    color: rgb(74, 73, 73);
    background-color: rgb(237, 238, 238);
  }

  .landing_desc {
    padding: 2vh 0;
  }
  #landing_industry {
    display: flex;
    flex-direction: column;
    padding: 3vw;
    padding-top: 5vh;
    background-color: white;
  }

  .industry_item {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1rem;
    font-weight: 250;
    padding: 1vw;
    padding-left: 0;
  }
}
