.no-scroll {
  overflow: hidden;
}
.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  margin-left: 9vw;
}
.nav-2{
  margin-left: 2vw;
}
.vanish {
  display: none;
}
.tagline {
  height: 10vh;
  width: 15vw;
}

.navlink {
  color: rgb(86, 86, 86);
  font-weight: 600;
  transition: 0.3s;
  cursor: pointer;
}

.navlink:hover {
  color: rgb(0, 0, 0);
}

.active {
  color: rgb(0, 0, 0);
  font-weight: 600;
}

/* //media fire */
@media screen and (max-width: 450px) {
  .mobile-nav {
    padding: 7px;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 6vh;
    align-items: center;
    z-index: 100;
    background: #f5f6f7;
    transition: transform 0.3s ease-in-out;
  }

  .hidden-nav {
    transform: translateY(-100%); 
}
  .mobile-widget {
    font-size: 18px;
    width: 17vw;
    font-weight: 600;
    margin-right: 12vw;
  }

  .toggle {
    position: absolute;
    top: 1.4vh;
    right: 2vw;
    margin-top: -1px;
  }

  .nav-1-2 {
    padding-top: 12vh;
    padding-bottom: 3vh;
    margin-left: -44vw;
    width: 122.5vw;
    background: #f5f6f7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 95;
  }
  .navlist {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    height: 70vh;
    width: 70vw;
  }
  .widgetlist {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 70vw;
  }
}
@media screen and (max-width: 900px) and (min-width: 451px) {
  .mobile-nav {
    padding: 7px;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 6vh;
    align-items: center;
    z-index: 100;
    background: rgb(230, 230, 230);
    opacity: 0.9;
  }
  .mobile-widget {
    font-size: 18px;
    width: 12vw;
    font-weight: 600;
    margin-right: 12vw;
  }

  .toggle {
    position: absolute;
    top: 1.8vh;
    right: 4vw;
    margin-top: -1px;
  }

  .nav-1-2 {
    padding-top: 12vh;
    padding-bottom: 3vh;
    margin-left: -43vw;
    width: 122.5vw;
    background: #f5f6f7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 95;
  }
  .navlist {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    height: 70vh;
    width: 70vw;
    
  }
  .widgetlist {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 70vw;
  }
}

/* transition settings */
/* @keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.transition-enter {
  animation: slideDown 0.3s ease-out forwards;
}

.transition-exit {
  animation: slideUp 0.3s forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-enter {
  animation: slideInFromLeft 0.5s forwards;
}

.slide-exit {
  animation: slideOutToLeft 0.5s forwards;
} */



/* Navbar Opening from Left */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Navbar Closing to the Left */
@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Apply Left-to-Right Slide Instead of Top-to-Bottom */
.transition-enter {
  animation: slideInFromLeft 0.3s ease-out forwards;
}

.transition-exit {
  animation: slideOutToLeft 0.3s ease-in forwards;
}

/* Menu Items Animation (Fade in after Navbar is Open) */
@keyframes fadeInMenuItems {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Menu Items Animation (Fade out before Navbar Closes) */
@keyframes fadeOutMenuItems {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-10px);
  }
}

/* Menu Items Fade in After Navbar */
.slide-enter {
  animation: fadeInMenuItems 0.4s ease-out forwards;
  animation-delay: 0.3s; /* Delay to sync with navbar */
  opacity: 0;
}

/* Menu Items Fade Out Before Navbar Closes */
.slide-exit {
  animation: fadeOutMenuItems 0.2s ease-in forwards;
}
