.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom  css starting here */
.page_content {
  margin-top: 7vh;
  z-index: 1;
}
.page_content_vanish {
  margin-top: 24vh;
  z-index: 1;
}
.page_component {
  width: 98vw;
  min-height: 30vh;
}

.gray{
  margin-top: 6vh;
  background-color: #eee;
}

.blue{
  margin-top: 5vh;
  background-color: #0f9eea;
}

.inner {
  width: 70vw;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 6vh 0;
}

.inner-normal{
  width: 70vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2vh 0;
}

.heading {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

.normal-text{
  font-size: 1.3rem;
  font-weight: 350;
}

.icons{
  height: 15px;
  width: 20px;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.sublink{
  text-decoration: none;
  color: #0077b5;
}

.sublink:hover {
  text-decoration: underline;
}

.images{
  height: 40vh;
  width: 35vw;
}

.btn {
  font-size: 1.2rem;
  color: white;
  padding: 5px 25px 7px 25px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  background-color: #0077b5;
}

.btn:hover {
  background-color: #0188d1;
  border-color: #0188d1;
  opacity: 0.9;
}

.flex-widget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-text {
  padding: 1vh 1vw;
}

.center_content{
  display: flex;
  justify-content: center;
}

.section_separator{
  width: 70vw;
}




@media screen and (max-width:450px) {
  .inner {
    width: 90vw;
    margin: 0 auto;
    flex-direction: column;
    padding: 2vh 0;
  }

  .inner-normal{
    width: 90vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 2vh 0;
  }

  .images{
    height: 40vh;
    width: 100%;
  }
  .btn {
    font-size: 1.2rem;
    color: white;
    padding: 5px 25px 7px 25px;
    font-weight: 400;
    cursor: pointer;
    border: none;
    transition: transform 0.3s ease;
    background-color: #0077b5;
  }

  .heading {
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .flex-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .flex-text {
    font-size: 1rem;
    padding: 1vh 1vw;
  }
}
@media screen and (max-width:900px) and (min-width:451px) {
  .inner {
    width: 90vw;
    margin: 0 auto;
    padding: 2vh 0;
  }
  .btn {
    font-size: 1.2rem;
    color: white;
    padding: 5px 25px 7px 25px;
    font-weight: 400;
    cursor: pointer;
    border: none;
    transition: transform 0.3s ease;
    background-color: #0077b5;
  }

  .images{
    height: 33vh;
    width: 60vw;
  }

  .heading {
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .flex-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .flex-text {
    font-size: 1.2rem;
    /* width: 30vw; */
    padding: 1vh 1vw;
  }
}