.popup {
  position: fixed;
  width: 100vw;
  height: 60vh;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.top-17vh {
  top: 17vh;
}

.popup-1 {
  width: 70vw;
  margin-top: -35vh;
  padding-left: 3vh;
  text-align: left;
  display: flex;
}

.widget-popup {
  width: 70vw;
  display: flex;
  justify-items: flex-end;
  text-align: left;
}

.popup-sections {
  padding-right: 2vw;
}

.subnavlink {
  @apply text-black text-[18px] font-semibold;
}

.subnavheading {
  @apply text-gray-800 text-[14px];
}

/* Search Utility */
.search-box {
  width: 80vw;
  height: 50px;
  font-size: 1.25rem;
  font-weight: 800;
  border: none;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  caret-color: rgb(129, 129, 129);
}
.search-box::placeholder {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: normal;
}
.clear-input {
  position: absolute;
  right: 31vw;
  top: 10.2vh;
}

.clearicon {
  color: white;
  background-color: rgb(151, 151, 151);
  font-size: 12px;
  border-radius: 50%;
  padding: 2px 3px;
  transition: all ease 0.5s;
}
.clearicon:hover {
  background-color: rgb(72, 72, 72);
}

@media screen and (max-width: 450px) {
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    margin-top: -12vh;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;

  }
  .popup-1 {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -9vh;
    margin-left: 0vw;
    padding-left: 7vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: #f5f6f7;
    z-index: 100;
  }

  .about-5 {
    margin-top: -3vh;
  }

  .products,
  .industries,
  .careers,
  .newsandmedia {
    padding-top: 8vh;
  }

  .widget-popup {
    display: flex;
    justify-items: flex-start;
    width: 100vw;
    margin-left: 7vw;
    margin-top: -80vh;
    text-align: left;
    background-color: white;
  }
  .account {
    height: 100vh;
    background-color: aqua;
  }

  .search-box {
    width: 90vw;
    height: 50px;
    font-size: 1.25rem;
    font-weight: 800;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    caret-color: rgb(129, 129, 129);
    background-color: #f5f5f5; /* Light gray background */
    color: #333; /* Dark text for better visibility */
    border-radius: 8px; /* Optional: Smooth rounded corners */
    padding-left: 15px; /* Adds spacing inside the input */
}

  .search-box::placeholder {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: normal;
  }

  .clear-input {
    position: absolute;
    right: 4vw;
    top: 15vh;
  }
}

@media screen and (max-width: 900px) and (min-width:451px) {
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    margin-top: -12vh;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
  }
  .popup-1 {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -9vh;
    margin-left: 0vw;
    padding-left: 5vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 100;
  }

  .about-5 {
    margin-top: -3vh;
  }

  .products,
  .industries,
  .careers,
  .newsandmedia {
    padding-top: 8vh;
  }

  .widget-popup {
    display: flex;
    justify-items: flex-start;
    width: 100vw;
    margin-left: 5vw;
    margin-top: -80vh;
    text-align: left;
    background-color: white;
  }
  .account {
    height: 100vh;
    background-color: aqua;
  }

  .search-box {
    width: 90vw;
    height: 50px;
    font-size: 1.25rem;
    font-weight: 800;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    caret-color: rgb(129, 129, 129);
  }

  .search-box::placeholder {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: normal;
  }

  .clear-input {
    position: absolute;
    right: 4vw;
    top: 15vh;
  }
}


