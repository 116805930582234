.fade-in {
    opacity: 0;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .slide-up {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromBottom 0.6s ease-out forwards;
  }
  
  .slide-down {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromTop 0.6s ease-out forwards;
  }
  
  @keyframes slideFromBottom {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideFromTop {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  