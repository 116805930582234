.blur {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 90;
}
.rotate-blur {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; */
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 90;
}
