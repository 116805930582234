.notfound{
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-start;
    height: 100vh;
}



.overlay{
    position: absolute;
    top: 30vh;
    right: 17vw;
    background: #f8f9fa;
    opacity: 0.9;
}

@media screen and (max-width:800px)  and (min-width:451px) {
    .overlay{
        position: absolute;
        top: 15.5vh;
        right: 17vw;
        background: #f8f9fa;
        opacity: 0.9;
    }  

    #notfound{
        height: 40vh;
        width: 100vw;
    }
}
@media screen and (max-width:451px){
    .overlay{
        position: absolute;
        top: 16vh;
        right: 12vw;
        background: #f8f9fa;
        opacity: 0.9;
    }  

    #notfound{
        height: 40vh;
        width: 100vw;
    }
}

