.footer{
    font-size: 14px;
    background-color: #eee;
}

.footer-body{
    width: 70vw;
  margin: 0 auto;
  padding: 3vh 0;
}

.footer-main{
    display: flex;
    flex-direction: row;
}

.footer-policy{
    display: flex;
    flex-direction: row;
}

.footer-list{
    text-align: left;
    margin: 0 3vw;
}

.footer-heading{
    font-size: 1.063em;
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
}

.footer-link{
    font-size:.85em;
    font-weight: 400;
    color: #4d4d4d;
}
.footer-link:hover{
    font-size:.85em;
    text-decoration: underline;
    font-weight: 400;
    color: #0077b5;
}

.footer-btn{
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: .35em 2em;
    font-weight: 400;
    cursor: pointer;
    border: none;
    transition: transform 0.3s ease;
    background-color: #009fdf;
    transition: opacity .5s ease;
}

.footer-btn:hover{
    
    background-color: #13b5f6;
}

.policy-link{
    padding-right: 2vw;
}


@media screen and (max-width: 768px){
    .footer-body{
        width: 90vw;
    }
    .footer-main{
        flex-direction: column;
    }
    .footer-list{
        margin: 0;
    }
    .footer-heading{
        margin-top: 10px;
    }
    .footer-policy{
        margin-top: 30px;
        flex-direction: column;
    }
    .policy-link{
        padding-right: 0;
    }
    
}